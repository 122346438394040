import React from 'react'
import {css} from '@emotion/core'
import Layout from '../../components/Layout'
import Container from '../../components/Container'
import SEO from '../../components/SEO'
import {rhythm} from '../../lib/typography'
import {truncate} from 'lodash'
import { Link, graphql } from 'gatsby'
import Workshop from 'components/workshops/workshop'
import Form from 'components/workshops/contact-form'

export default function RemoteWorkshops({data: {workshops}}) {
    return (
      <Layout>
        <SEO />
        <Container noVerticalPadding>  
          <div
            css={css`
              text-align: center;
              margin: ${rhythm(2)} 0 ${rhythm(1.5)} 0;
            `}
          >
            <h1>Available Workshops</h1>
            <p>Workshops are available either remote or on-site. </p>
            <div
              css={css`
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
              `}
            >
            </div>
            
            <Form />
            
            Interested in private training for your company? <Link to="/contact">Contact me</Link> for rates and availability.
          </div>
          <div
            css={css`
              display: grid;
              grid-gap: 20px;
              grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
              margin-bottom: ${rhythm(2)};
            `}
          >
            {workshops.edges
              .map(({node: workshop}) => (
                <Workshop
                  key={workshop.id}
                  title={workshop.frontmatter.title}
                  description={truncate(workshop.frontmatter.description, {
                    length: 190,
                  })}
                  url={
                    workshop.fields.slug
                      ? workshop.fields.slug
                      : `/workshops/${workshop.frontmatter.slug}`
                  }
                  tech={workshop.frontmatter.tech}
                />
              ))}
          </div>
        </Container>
      </Layout>
    )
  }


export const pageQuery = graphql`
query {
  workshops: allMdx(
    filter: {fields: {isWorkshop: {eq: true}}}
    sort: {order: ASC, fields: [frontmatter___order]}
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          date
          description
          slug
        }
        fields {
          slug
        }
      }
    }
    totalCount
  }
}
`
